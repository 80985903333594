<template>



  <div class="ele-body">
    <el-card shadow="never">

      <!-- 搜索表单 -->
      <div>
        <el-form :model="table.where" class="ele-form-search d-flexspabet" @keyup.enter.native="$refs.table.reload()"
          @submit.native.prevent>

          <!-- <el-button @click="showEdit=true" class="ele-btn-icon addbtn" size="small"
                        v-if="permission.includes('sys:rreach:add')">添加违约金设置
                    </el-button> -->

          <div class="d-flex" style="display: flex;">
            <el-form-item label="省:" label-width="35px" class="w-170">
              <el-select v-model="table.where.pid" @change="handleChangeProv(table.where.pid)" placeholder="请选择省"
                clearable>
                <el-option v-for="option in provArr" :label="option.name" :value="option.pid" :key="option.pid">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="市:" label-width="35px" class="w-150">
              <el-select v-model="table.where.cid" @change="handleChangeCity(table.where.cid),$forceUpdate()"
                placeholder="请选择市" clearable>
                <el-option v-for="option in cityArr" :label="option.name" :value="option.cid" :key="option.cid">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="县/区:" label-width="62px" class="w-200 mr-20">
              <el-select v-model="table.where.aid" @change="$forceUpdate()" placeholder="请选择县/区" clearable>
                <el-option v-for="option in districtArr " :label="option.name" :value="option.aid" :key="option.aid">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="司机手机号:" label-width="90px" class="w-300">
              <el-input v-model="table.where.driver_phone" width="100px"  type="number" placeholder="请输入司机手机号" clearable />
            </el-form-item>
            <el-form-item label="司机姓名:" label-width="90px" class="w-300">
              <el-input v-model="table.where.driver_name"  placeholder="请输入司机姓名" clearable />
            </el-form-item>
            <el-form-item label="司机车牌号:" label-width="100px" class="w-300">
              <el-input v-model="table.where.car_number" placeholder="请输入司机车牌号" clearable />
            </el-form-item>
            <el-form-item label="订单编号:" label-width="90px" class="w-300">
              <el-input v-model="table.where.order_id" placeholder="请输入订单编号" clearable />
            </el-form-item>
            <el-form-item label="用户手机号:" label-width="100px" class="w-300">
              <el-input v-model="table.where.place_phone" type="number" placeholder="请输入发单用户手机号" clearable />
            </el-form-item>
            <el-form-item style="margin-left: 20px" label="轨迹创建时间:" label-width="100px">
              <el-date-picker v-model="table.where.create_time" type="date" value-format="yyyy-MM-dd"
                placeholder="选择开始时间" clearable>
              </el-date-picker>
              至
              <el-date-picker v-model="table.where.update_time" type="date" value-format="yyyy-MM-dd"
                placeholder="选择结束时间" clearable>
              </el-date-picker>
            </el-form-item>

            <el-form-item>
              <el-button style="margin-left: 10px;line-height:15px" type="primary" @click="$refs.table.reload()"
                icon="el-icon-search" class="ele-btn-icon">搜索</el-button>
              <!-- <el-button  class="ele-btn-icon" @click="(table.where={})&&$refs.table.reload()">重置</el-button> -->
            </el-form-item>
            <el-form-item>
              <!-- <el-button type="primary" @click="$refs.table.reload()" icon="el-icon-search"
                            class="ele-btn-icon">搜索</el-button> -->
              <el-button style="margin-left: 10px;line-height:15px " class="ele-btn-icon"
                @click="(table.where={})&&$refs.table.reload()">重置</el-button>
            </el-form-item>

          </div>
        </el-form>
      </div>


      <!-- 数据表格 -->
      <ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)" highlight-current-row :stripe="true" >
        <template slot-scope="{index}">
          <el-table-column type="selection" width="45" align="center"  />
          <el-table-column type="index" :index="index" label="编号" width="80" align="center"
            show-overflow-tooltip />
          <el-table-column prop="province" label="省" align="center" show-overflow-tooltip style="width: auto;"/>
          <el-table-column prop="city" label="市" align="center" show-overflow-tooltip style="width: auto;" />
          <el-table-column prop="area" label="区/县" align="center" show-overflow-tooltip min-width="50" />
          <el-table-column prop="driver_phone" label="司机手机号" align="center" show-overflow-tooltip width="180" />
          <el-table-column prop="driver_name" label="司机姓名" align="center" show-overflow-tooltip width="80" />
          <el-table-column prop="order_id" label="订单编号" align="center" show-overflow-tooltip width="200" />
          <el-table-column prop="place_phone" label="用户手机号" align="center" show-overflow-tooltip width="180" />
          <el-table-column prop="car_number" label="司机车牌号" align="center" show-overflow-tooltip width="100" />
          <el-table-column label="用户轨迹创建时间" align="center" show-overflow-tooltip min-width="100">
            <template slot-scope="{row}">{{ row.create_time*1000 | toDateString }}</template>
          </el-table-column>
          <el-table-column label="操作" min-width="160px" align="center" :resizable="false" >
            <template slot-scope="{row}">
              <el-link @click="edit(row)" slot="reference" icon="el-icon-edit" type="primary" :underline="false"
                v-if="permission.includes('sys:map:index')">查看</el-link>
            </template>
          </el-table-column>
        </template>
      </ele-data-table>
    </el-card>





    <!-- 编辑弹窗 -->
    <!--添加/编辑弹窗 -->
    <el-dialog v-dialogDrag :title="editForm.id?'查看车辆行驶轨迹':'添加用户轨迹'" :visible.sync="showEdit" @closed="editForm={}"
      :destroy-on-close="true" :lock-scroll="false" style="margin-top: 0px ;">
      <el-card shadow="never">
      </el-card>
      <div slot="footer" style=" width: 100%;background-color:white ;height: 100%; ">
        <ElDialogMapTrack :options="options"></ElDialogMapTrack>
      </div>
    </el-dialog>

  </div>


  <!-- <div id="container" style="width:50%;height:90vh"/> -->


</template>
<script type="text/javascript" src="https://webapi.amap.com/maps?v=2.0&key=01b48c4c3849357936137213bdd8ea42&plugin=AMap.Geolocation,AMap.Autocomplete,AMap.PlaceSearch,AMap.Geocoder,AMap.PolyEditor"></script>
<script>
// import car from '@/assets/images/logo.png' //引用标注图标
import download from '@/components/Download/index'
// var lineArr = [[116.478935, 39.997761], [108.983569, 34.285675], [103.85094, 35.987496], [106.205794, 38.458831], [111.761777, 40.875595]]
import {
  mapGetters
} from "vuex";
import ElDialogMapTrack from '@/components/ElDialogMapTrack'

import { Icon } from 'element-ui';
import icon from './car.png'
export default {
  name: "SysUser",
  provide() { // 父组件中返回要传给下级的数据
    return {
      reload: this.reload
    }
  },
  components:{
    ElDialogMapTrack
		},
  data() {
    return {
      options: {
        row: {},
        lineArr: ''
      },
      isRouterAlive: true,
      table: {
        url: '/driver/getTrajectory',
        where: {}
      }, // 表格配置
      choose: [], // 表格选中数据
      showEdit: false, // 是否显示表单弹窗
      editForm: {}, // 表单数据
      editRules: { // 表单验证规则
        pid: [{
          required: true,
          message: '请选择省份',
          trigger: 'change'
        }],
        search_keyword: [{
          required: true,
          message: '请输入用户叫单关键词',
          trigger: 'blur'
        }]
      },
      provArr: [],
      cityArr: [],
      districtArr: [],
      activeName: 'first',
      activeEdit: 'run-driver',
      pid: '',
      cid: '',
      aid: '',
      changePro: false,
      changeCity: false,
      changeArea: false,
      firstArr: '',
      lineArr: '',
      place_address: '',
      target_address: '',
      newPath: ''
      // var lineArr = [[116.478935, 39.997761], [108.983569, 34.285675], [103.85094, 35.987496], [106.205794, 38.458831], [111.761777, 40.875595]]

    }
  },
  created() {
    this.$http.get('/common/province_list').then(res => {
      let data = JSON.parse(res.data)
      this.provArr = data
    })

  },
  computed: {
    ...mapGetters(["permission"]),
  },
  mounted() {
    // setTimeout(() => {
    //   //console.log(22323)
    //   this.initMap(this.lineArr) // 异步加载（否则报错initMap is not defined）
    //   // this.initroad()
    // }, 1000)

  },
  methods: {
    handleChangeDriverPhone(e){
      this.table.where.driver_phone=e;
    },
    
   
    handleChangeDriverName(e){
      this.table.where.driver_name=e;
    },
    /**
     *选择省
     **/
    handleChangeProv(e) {
      /** 获取被选省份的pid**/
      let pid = ''
      this.provArr.forEach(function (item) {
        if (item.pid == e) {
          pid = item.pid
        }
      })
      /** 根据被选省份的pid获取省市下面的市**/
      this.$http.post('/common/city_list', {
        pid: pid
      }).then(res => {
        let data = JSON.parse(res.data)
        this.cityArr = data
        /** 选择省份清空市县**/
        this.table.where.cid = ''
        this.table.where.aid = ''
      })
    },
    /**
     *选择市
     **/
    handleChangeCity(e) {
      if (e) {
        /** 获取被选市的cid**/
        let cid = ''
        this.cityArr.forEach(function (item) {
          if (item.cid == e) {
            cid = item.cid
          }
        })
        /** 根据被选市的cid获取市下面的县**/
        this.$http.post('/common/area_list', {
          cid: cid
        }).then(res => {
          let data = JSON.parse(res.data)
          this.districtArr = data
          /** 选择省份清空县**/
          this.table.where.aid = ''
        })
      } else {
        // this.table.where.pid = ''
        this.table.where.aid = ''
      }
    },

    /**
     *修改省
     **/
    handleChangeProv1(e) {
      /** 获取被选省份的pid**/
      if (e) {
        let pid = ''
        let that = this
        this.changePro = true
        this.provArr.forEach(function (item) {
          if (item.pid == e) {
            pid = item.pid
          }
        })
        /** 根据被选省份的pid获取省市下面的市**/
        this.$http.post('/common/city_list', {
          pid: pid
        }).then(res => {
          let data = JSON.parse(res.data)
          that.cityArr = data
          /** 选择省份清空市县**/
          this.editForm.cid = ''
          this.editForm.aid = ''
          this.editForm.cname = ''
          this.editForm.aname = ''
        })
      } else {
        this.editForm.pid = ''
        this.editForm.cid = ''
        this.editForm.aid = ''
        this.editForm.pname = ''
        this.editForm.cname = ''
        this.editForm.aname = ''
      }

    },
    /**
     *修改市
     **/
    handleChangeCity1(e) {
      if (e) {
        /** 获取被选市的cid**/
        let cid = ''
        this.changeCity = true
        this.cityArr.forEach(function (item) {
          if (item.cid == e) {
            cid = item.cid
          }
        })
        /** 根据被选市的cid获取市下面的县**/
        this.$http.post('/common/area_list', {
          cid: cid
        }).then(res => {
          let data = JSON.parse(res.data)
          this.districtArr = data
          /** 选择省份清空县**/
          this.editForm.aid = ''
          this.editForm.aname = ''
        })
      } else {
        // this.editForm.cname = ''
        this.editForm.aid = ''
        this.editForm.aname = ''
      }
    },
    handleDis() {
      this.changeArea = true
    },
    /**
     * 显示编辑
     */
    edit(row) {
      
      this.$http.post('/driver/getOneTrajectory', {
        order_id: row.order_id
      }).then(res => {
        this.options.row = row;
        this.options.lineArr = res.data.data.driver_place;
       // let data = JSON.parse(res.data);
        //console.log('1111111111111guji111111111111',res.data.data.driver_place)
        this.pid = row.pid
        this.cid = row.cid
        this.aid = row.aid
        row.driver_place = res.data.data.driver_place
        this.lineArr = row.driver_place
        this.editForm = row
        this.showEdit = true;
        this.target_address = row.target_address
        this.place_address = row.place_address
        // this.initMap(row)
      })
      // }
      // this.initroad(this.lineArr)
      // if (this.editForm.pid > 0) {
      //   this.$http.post('/common/city_list', {
      //     pid: this.editForm.pid
      //   }).then(res => {
      //     let cdata = JSON.parse(res.data)
      //     this.cityArr = cdata
      //   })
      // }
      // if (this.editForm.cid > 0) {
      //   this.$http.post('/common/area_list', {
      //     cid: this.editForm.cid
      //   }).then(res => {
      //     let adata = JSON.parse(res.data)
      //     this.districtArr = adata
      //   })
      // }

    },

  }
}
</script>

<style  scoped>
/deep/.el-dialog .el-dialog__body {
  display: none;
}

html {
  font-size: 12px;
}

.amap-copyright {
  box-sizing: content-box
}

* {
  box-sizing: border-box
}

.input-textarea {
  color: grey;
  height: 8em;
  overflow: auto;
  border-radius: 0.4rem;
  border: 1px solid #ced4da;
  margin-bottom: 1rem;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 1.5;
  font-weight: 300;
  color: #111213;
}

hr {
  margin: 0.5rem 0;
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, .1)
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

label {
  display: inline-block;
  margin-bottom: 0.4rem;
}

label,
.btn {
  margin-left: 0;
  font-size: 1rem;
}

button,
input,
select {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  overflow: visible;
  text-transform: none
}

[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=checkbox],
input[type=radio] {
  box-sizing: border-box;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  margin: 0 0.5rem 0 0;
}

h4 {
  font-family: inherit;
  line-height: 1.8;
  font-weight: 300;
  color: inherit;
  font-size: 1.1rem;
  margin-top: 0;
  margin-bottom: .5rem
}

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  background-color: transparent;
  background-image: none;
  color: #25A5F7;
  border-color: #25A5F7;
  padding: .25rem .5rem;
  line-height: 1.5;
  border-radius: 1rem;
  -webkit-appearance: button;
  cursor: pointer;
}

.btn:hover {
  color: #fff;
  background-color: #25A5F7;
  border-color: #25A5F7
}

.btn:hover {
  text-decoration: none
}

.input-item {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 3rem;
}

.input-item:last-child {
  margin-bottom: 0;
}

.input-item>select,
.input-item>input[type=text],
.input-item>input[type=date] {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.input-item>select:not(:last-child),
.input-item>input[type=text]:not(:last-child),
.input-item>input[type=date]:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0
}

.input-item>select:not(:first-child),
.input-item>input[type=text]:not(:first-child),
.input-item>input[type=date]:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0
}

.input-item-prepend {
  margin-right: -1px;
}

.input-item-text,
input[type=text],
input[type=date],
select {
  height: calc(2.2rem + 2px);
}

.input-item-text {
  width: 6rem;
  text-align: justify;
  padding: 0.4rem 0.7rem;
  display: inline-block;
  text-justify: distribute-all-lines;
  /*ie6-8*/
  text-align-last: justify;
  /* ie9*/
  -moz-text-align-last: justify;
  /*ff*/
  -webkit-text-align-last: justify;
  /*chrome 20+*/
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.input-item-text input[type=checkbox],
.input-item-text input[type=radio] {
  margin-top: 0
}

.input-card {
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-radius: .25rem;
  width: 22rem;
  border-width: 0;
  border-radius: 0.4rem;
  box-shadow: 0 2px 6px 0 rgba(114, 124, 245, .5);
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 0.75rem 1.25rem;
}

.input-text {
  line-height: 2rem;
  margin-right: 2rem;
}

.info hr {
  margin-right: 0;
  margin-left: 0;
  border-top-color: grey;
}

.info {
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border-radius: .25rem;
  position: fixed;
  top: 1rem;
  background-color: white;
  width: auto;
  min-width: 22rem;
  border-width: 0;
  right: 1rem;
  box-shadow: 0 2px 6px 0 rgba(114, 124, 245, .5);
}

.code {
  left: 1.5rem;
  right: 1.5rem;
  top: 1.5rem;
  bottom: 1.5rem;
  overflow: auto;
  margin-bottom: 0rem;
}

.code .btn {
  top: 1rem;
  position: absolute;
  right: 1rem;
}

.code .result {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  padding: 1rem;
  bottom: 1rem;
  position: absolute;
  top: 5.5rem;
  right: 1rem;
  left: 1rem;
  overflow: auto;
}

.code .status {
  color: #80adff;
  display: inline-block;
  font-size: 14px;
}

.code h4 {
  display: inline-block;
  max-width: 20rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

select,
input[type=text],
input[type=date] {
  display: inline-block;
  width: 100%;
  padding: .375rem 1.75rem .375rem .75rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right .75rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none
}

input[type=text],
input[type=date] {
  background: #fff;
  padding: .375rem .75rem;
}

select:focus,
input[type=text]:focus,
input[type=date]:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 .1rem rgba(128, 189, 255, .1)
}

.btn:focus {
  outline: 0;
  box-shadow: none;
}

select:focus::-ms-value,
input[type=text]:focus::-ms-value,
input[type=date]:focus::-ms-value {
  color: #495057;
  background-color: #fff
}


/* native toastr */
.native-toast {
  position: fixed;
  background-color: rgba(50, 50, 50, .8);
  border-radius: 33px;
  color: white;
  left: 50%;
  text-align: center;
  padding: 6px 12px;
  opacity: 0;
  z-index: 99999;
  transition: transform .25s, opacity .25s, top .25s;
  box-sizing: border-box;
}

.native-toast-bottom {
  bottom: 50px;
  -ms-transform: translateX(-50%) translateY(50px);
  transform: translateX(-50%) translateY(50px)
}

.native-toast-bottom.native-toast-shown {
  opacity: 1;
  -ms-transform: translateX(-50%) translateY(0);
  transform: translateX(-50%) translateY(0);
}

.native-toast-bottom.native-toast-edge {
  bottom: 0;
}

.native-toast-top {
  top: 50px;
  -ms-transform: translateX(-50%) translateY(-50px);
  transform: translateX(-50%) translateY(-50px)
}

.native-toast-top.native-toast-shown {
  opacity: 1;
  -ms-transform: translateX(-50%) translateY(0);
  transform: translateX(-50%) translateY(0);
}

.native-toast-top.native-toast-edge {
  top: 0;
}

.native-toast-center {
  top: 0;
  -ms-transform: translateX(-50%) translateY(-50px);
  transform: translateX(-50%) translateY(-50px)
}

.native-toast-center.native-toast-shown {
  opacity: 1;
  top: 50%;
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.native-toast-edge {
  border-radius: 0;
  width: 100%;
  text-align: left;
}

@media screen and (min-width: 40rem) {
  .native-toast:not(.native-toast-edge) {
    max-width: 18rem;
  }
}

/*
  max-width does not seem to work in small screen?
*/

/*@media screen and (max-width: 768px) {
  .native-toast:not(.native-toast-edge) {
    max-width: 400px;
  }
}

@media screen and (max-width: 468px) {
  .native-toast:not(.native-toast-edge) {
    max-width: 300px;
  }
}*/

/* types */


.native-toast-error {
  background-color: #d92727;
  color: white;
}

.native-toast-success {
  background-color: #62a465;
  color: white;
}

.native-toast-warning {
  background-color: #fdaf17;
  color: white;
}

.native-toast-info {
  background-color: #5060ba;
  color: white;
}

[class^="native-toast-icon-"] {
  vertical-align: middle;
  margin-right: 8px
}

[class^="native-toast-icon-"] svg {
  width: 16px;
  height: 16px;
}
</style>
